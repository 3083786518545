import { Component, Vue } from 'vue-property-decorator';
import template from './CreerUtilisateur.Template.vue';
import ContainerForm from '@/components/FormsUtilisateur/ContainerForm.vue';

@Component({
    ...template,
    name: 'CreerUtilisateur',
    components: {ContainerForm},
})
export default class CreerUtilisateur extends Vue {

}

