



























import { Component, Watch, Mixins } from 'vue-property-decorator';
import FormValidator from './Form.Validator';
import { Utilisateur } from '@/models';
import { TypeProfilUtilisateur } from '@/shared/enums/TypeProfilUtilisateur.enum';

import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Getter } from 'vuex-class';

@Component({
    name: 'ConnexionForm',
})
export default class ConnexionForm extends Mixins(FormValidator) {

    /**
     * Permet de savoir si on crée un utilisateur externe ou pas.
     */
    public get estCreationUtilisateurInterne(): boolean {
        return this.typeProfil === TypeProfilUtilisateur.Interne;
    }

    /**
     * L'utilisateur du store.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;

    /**
     * Getter et setter pour le type de profil de l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.TYPE_PROFIL)
    private typeProfil: TypeProfilUtilisateur;

    // La confirmation du mail.
    public confirmationEmail: string = null;

    // Confirmation que les informations sont exactes.
    private confirmationInformations = false;

    // Si oui ou non les CGU sont acceptées.
    private acceptationCGU = false;

    /// Permet de savoir si l'email et l'email de confirmation sont identiques.
    public get emailConfirmationRule(): (() => boolean | string) {
        return () => (this.utilisateur.email === this.confirmationEmail) || 'L\'adresse mail de confirmation est incorrecte.';
    }

    /**
     * Watcher pour faire en sorte que le userName soit égal à l'email.
     * */
    @Watch('utilisateur.email', { immediate: true })
    public onUtilisateurEmailChanged() {
        if (!this.estCreationUtilisateurInterne) {
            // Si c'est un externe on met son Login=Email.
            this.utilisateur.userName = this.utilisateur.email;
        }
    }
}

