

























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PrescripteurInterne, Utilisateur } from '@/models';
import FormValidator from './Form.Validator';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { Getter } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';

@Component({
    name: 'PrescripteurInterneForm',
    components: { CeeAutocomplete },
})
export default class PrescripteurInterneForm extends Mixins(FormValidator) {

    /**
     * Getter pour l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;


    @Prop()
    public prescripteursInternesPromise: (recherche: string) => Promise<PrescripteurInterne[]>;

    // Indique si il faut montrer le bloc.
    @Prop({ default: false })
    private isVisible!: boolean;

    // Coche si il y a un prescripteur interne.
    private isPrescripteurRequired = true;
}

