





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AccordCadre, ContratApporteursAffaires } from '@/models';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Getter } from 'vuex-class';
import { TypeProfilUtilisateur } from '@/shared/enums/TypeProfilUtilisateur.enum';
import { Profils } from '@/shared/enums';

@Component({
    name: 'ContratForm',
    components: { CeeAutocomplete },
})
export default class ContratForm extends Vue {

    /**
     * Le type de profil Interne/Externe sélectionné.
     */
    @Getter(UtilisateurStoreMethods.TYPE_PROFIL)
    private typeProfil: TypeProfilUtilisateur;

    @Getter(UtilisateurStoreMethods.PROFIL)
    public profil: Profils;

    @Prop()
    public apporteursAffairesPromise: (nom: string) => Promise<ContratApporteursAffaires[]>;
    @Prop()
    public accordCadresPromise: (nom: string) => Promise<AccordCadre[]>;

    private accordsCadresId = 0;

    private apporteursAffairesId = 0;

    public get label() {
        return (this.profil === Profils.ApporteurAffaires && this.typeProfil === TypeProfilUtilisateur.Externe) ?
            'Contrat Apporteur d\'affaires' :
            this.typeProfil === TypeProfilUtilisateur.Externe ? 'Contrat accord-cadre' : '';
    }

    @Watch('accordsCadresId')
    public onSelectedAccordCadre() {
        this.$emit('onSelectedAccordCadre', this.accordsCadresId);
    }
    @Watch('apporteursAffairesId')
    public onSelectedApporteurAffaire() {
        this.$emit('onSelectedApporteurAffaire', this.apporteursAffairesId);
    }
    public isProfilDefinedAndExterne(): boolean {
        return this.typeProfil === TypeProfilUtilisateur.Externe && this.profil !== Profils.None;
    }
    public isApporteurAffaire(): boolean {
        return this.profil === Profils.ApporteurAffaires;
    }
}
