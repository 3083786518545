











































import { Component, Vue } from 'vue-property-decorator';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { ValidationObserver } from 'vee-validate';
import { Getter, Mutation, Action } from 'vuex-class';
import { Utilisateur, PrescripteurInterne, Profil, ContratApporteursAffaires, AccordCadre, AccordsCadresSociete } from '@/models';
import { Profils } from '@/shared/enums';
import ProfileForm from '@/components/FormsUtilisateur/ProfileForm.vue';
import ConnexionForm from '@/components/FormsUtilisateur/ConnexionForm.vue';
import IdentityForm from '@/components/FormsUtilisateur/IdentityForm.vue';
import SocieteForm from '@/components/FormsUtilisateur/SocieteForm.vue';
import ContratForm from '@/components/FormsUtilisateur/ContratForm.vue';
import PrescripteurInterneForm from '@/components/FormsUtilisateur/PrescripteurInterneForm.vue';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { UtilisateurApiHelper } from '@/services/UtilisateurApiHelper';
import { ApiAutocompleteHelper } from '@/services/ApiAutocompleteHelper';
import { TypeProfilUtilisateur } from '@/shared/enums/TypeProfilUtilisateur.enum';

@Component({
    name: 'ContainerForm',
    components: { ProfileForm, ConnexionForm, IdentityForm, SocieteForm, PrescripteurInterneForm, ContratForm, ValidationObserver },
})
export default class ContainerForm extends Vue {

    public $refs!: {
        form: HTMLFormElement;
    };

    /**
     * L'utilisateur présent dans le store.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private persistentUser: Utilisateur;

    /**
     * Mise à jour de l'utilisateur présent dans le store.
     */
    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR)
    private setUtilisateur: (utilisateur: Utilisateur) => void;

    @Action(UtilisateurStoreMethods.CHANGE_TYPE_PROFIL)
    private setTypeProfil: (type: TypeProfilUtilisateur) => void;

    @Getter(UtilisateurStoreMethods.PROFIL)
    public profil: Profils;

    /**
     * Le type de profil Interne/Externe sélectionné.
     */
    @Getter(UtilisateurStoreMethods.TYPE_PROFIL)
    private typeProfil: TypeProfilUtilisateur;

    /**
     * Persistence de la liste des profils sélectionnables.
     */
    @Mutation(UtilisateurStoreMethods.SET_PROFILS)
    private setProfils: (profils: Profil[]) => void;

    /**
     * Détermine un certain nombre de composant à montrer ou pas.
     */
    public get isUtilisateurExterne(): boolean {
        return this.typeProfil === TypeProfilUtilisateur.Externe;
    }

    /**
     * Indique si il y a un chargement en cours.
     */
    private loading = false;

    private prescripteursInternes: PrescripteurInterne[] = [];
    private accordCadres!: AccordCadre[];
    private apporteursAffaires!: ContratApporteursAffaires[];

    private apporteursAffairesId = 0;
    private accordsCadresId = 0;

    /**
     * Montage de la vue.
     * */
    public mounted() {
        // Récupération des données utilisées dans la vue et ses enfant.
        ApiHelper.getAll<Profil>('profil/obtenirTous').then(response => {
            this.setProfils(response);
        });
    }

    /**
     * Promesse pour l'autocomplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesPromise(recherche: string): Promise<PrescripteurInterne[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<PrescripteurInterne>(recherche, `/prescripteurInterne/obtenirTous`);
    }

    /**
     * Promesse pour l'autocomplete des contrats d'apporteur d'affaires.
     * @param nom Critère de recherche sur le nom.
     */
    public apporteursAffairesPromise(nom: string): Promise<ContratApporteursAffaires[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<ContratApporteursAffaires>(nom, `/contratApporteursAffaires/obtenirTous`)
            .then(reponse => this.apporteursAffaires = reponse);
    }

    /**
     * Promesse pour l'autocomplete des accords-cadres.
     * @param nom Critère de recherche sur le nom.
     */
    public accordCadresPromise(nom: string): Promise<AccordCadre[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<AccordCadre>(nom, `/accordCadre/obtenirTous`, true)
            .then(reponse => this.accordCadres = reponse);
    }

    public refreshAAId(value: number) {
        this.apporteursAffairesId = value;
    }
    public refreshACId(value: number) {
        this.accordsCadresId = value;
    }

    /**
     * Soumission du formulaire. Enregistrement de l'utilisateur.
     * */
    public submit(): void {
        const redirectTo: string = this.$route.name === 'creer-utilisateur-avec-profil' ? 'ajout-accord-cadre' : 'gestion-utilisateurs';
        if (this.$refs.form.validate()) {
            this.loading = true;
            UtilisateurApiHelper.creerAdminUtilisateur(
                {
                    utilisateur: this.persistentUser,
                })
                .then((result: any) => {
                    if (this.profil !== Profils.ApporteurAffaires && this.accordsCadresId !== 0) {
                        const accordCadreSocieteService = new ApiService<AccordsCadresSociete>('accordCadreSociete/creer');
                        for (const membre of result.data.data.societes) {
                            const accordcadresociete = new AccordsCadresSociete(this.accordsCadresId, membre.id, membre, 3);
                            accordCadreSocieteService.post(accordcadresociete);
                        }
                    }
                    if (this.profil === Profils.ApporteurAffaires && this.apporteursAffairesId !== 0) {
                        const contratAAService = new ApiService<ContratApporteursAffaires>('contratApporteursAffaires/modifier');
                        const anArray = this.apporteursAffaires.filter(
                            element => {
                                return element.id === this.apporteursAffairesId;
                            });
                        const apporteurAffaireToUpdate: ContratApporteursAffaires = anArray[0];
                        apporteurAffaireToUpdate.utilisateurId = result.data.data.id;
                        contratAAService.put(apporteurAffaireToUpdate);
                    }
                    if (result && result.data && !result.data.isError) {
                        (this.$router as any).push({ name: redirectTo });
                    }
                })
                .finally(() => this.loading = false);
        }
    }

    /**
     * Annulation des modifications sur l'utilisateur.
     * */
    public clear(): void {
        this.setUtilisateur(new Utilisateur());
        this.$router.push({ name: 'gestion-utilisateurs' }).catch(() => { /*Laisse vide express */ });
    }

    public destroyed() {
        // Reset de l'utilisateur si jamais on change d'utilisateur ensuite.
        this.setUtilisateur(new Utilisateur());
        this.setTypeProfil(TypeProfilUtilisateur.None);
    }
}
