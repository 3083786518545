


























import { Component, Mixins } from 'vue-property-decorator';
import { CompteValidationMixin } from '@/shared/mixins';
import { Getter, Mutation, Action } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { Utilisateur, Profil } from '@/models';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { TypeCompteSyndic } from '@/models/TypeCompteSyndic.model';

import { Profils } from '@/shared/enums';
import { TypeProfilUtilisateur } from '@/shared/enums/TypeProfilUtilisateur.enum';

/**
* Composant formulaire pour la sélection d'un profil utilisateur.
* */
@Component({
    name: 'ProfileForm',
})
export default class ProfileForm extends Mixins(CompteValidationMixin) {

    /**
     * Les type des profils affichés dans la liste.
     */
    private typeProfileItems: TypeProfilUtilisateur[] =
        [TypeProfilUtilisateur.Interne, TypeProfilUtilisateur.Externe];

    /**
     * Getter pour l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;

    /**
     * Getter des profils sélectionnables.
     */
    @Getter(UtilisateurStoreMethods.PROFILS)
    private profils: Profil[];

    /**
     * Getter et setter pour le type de profil de l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.TYPE_PROFIL)
    private typeProfil: TypeProfilUtilisateur;

    @Action(UtilisateurStoreMethods.CHANGE_TYPE_PROFIL)
    private setTypeProfil: (value: TypeProfilUtilisateur) => void;

    /**
     * Getter pour le type de compte de l'utilisateur Syndic/SDC.
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeCompteSyndic))
    public typesCompteSyndic: TypeCompteSyndic[];

    @Getter(UtilisateurStoreMethods.PROFIL)
    private profil: Profils;

    @Action(UtilisateurStoreMethods.CHANGE_PROFIL)
    private setProfil: (value: Profils) => void;

    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR_BY_PROPERTIES)
    private setPropertyUtilisateur: (property: object) => void;

    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    /**
     * Getter et setter de la propriété selectedTypeProfile.
     */
    private get selectedTypeProfile() {
        return this.typeProfil;
    }
    private set selectedTypeProfile(value: TypeProfilUtilisateur) {
        this.setTypeProfil(value);
    }

    /**
     * Montage de la vue.
     * */
    public mounted() {
        // Si on a un profil dans la route c'est que c'est un profil externe donc on force le profil externe.
        if (this.$route.params.profil) {
            this.setTypeProfil(TypeProfilUtilisateur.Externe);
            // A TESTER : this.set(Profils[this.$route.params.profil as any]);
        }

        // Récupération des valeurs référentielles.
        this.getValeursReferentielles(TypeValeurReferentielle.TypeCompteSyndic);
    }

    /**
     * Getters pour avoir la liste de profils utilisateur par type de profil interne/externe.
     */
    public get specificProfileByType() {
        return this.profils
            .filter(item => (item.isIntern ? TypeProfilUtilisateur.Interne : TypeProfilUtilisateur.Externe) === this.typeProfil);
    }

    public resetProfilUtilisateur(): void {
        this.utilisateur.profilUtilisateur = Profils.None;
        this.setProfil(Profils.None);
    }

    public isProfilDisabled(): boolean {
        return this.selectedTypeProfile === TypeProfilUtilisateur.None;
    }

    public set(value: Profils) {
        this.setPropertyUtilisateur({ profilUtilisateur: value });
        this.setProfil(value);
    }
}

